import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/main.scss';
import App from './screens';
import * as serviceWorker from './serviceWorker';

// if (!('createImageBitmap' in window)) {
//   (window as any).createImageBitmap = async function (data: any) {
//     return new Promise((resolve, reject) => {
//       let dataURL;
//       if (data instanceof Blob) {
//         dataURL = URL.createObjectURL(data);
//       } else if (data instanceof ImageData) {
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
//         canvas.width = data.width;
//         canvas.height = data.height;
//         ctx?.putImageData(data, 0, 0);
//         dataURL = canvas.toDataURL();
//       } else {
//         throw new Error('createImageBitmap does not handle the provided image source type');
//       }
//       const img = document.createElement('img');
//       img.addEventListener('load', function () {
//         resolve(this);
//       });
//       img.src = dataURL;
//     });
//   };
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
