import initBuffers from './initBuffers.js';
import ProgramInfo from './helpers/programInfo';
import Texture, { Positioning } from './Texture';
import Part from './Part';
import draw from './draw';
import drawPart from './drawPart';
import drawEnvironment from './drawEnvironment';

export default class OpenGL {
  gl: WebGLRenderingContext | null;
  canvas: any;
  programInfo: any;
  environmentProgramInfo: any;
  displayProgramInfo: any;
  buffers: any;

  _mask: Part | null = null;
  _wall: Texture | null = null;
  _wallSet: boolean = false;
  _shadow: Part | null = null;
  _shadowSet: boolean = false;
  _floor: Part | null = null;
  _floorSet: boolean = false;
  _floorLight: Texture | null = null;
  _floorLightSet: boolean = false;

  _transition: number = 1;
  _transitionDelta: number = 0.01;

  _parts: Part[] = [];

  _playing: boolean = false;
  _direction: number = 1;
  _angle: number = 0;
  _previousAngle: number = 0;
  _previousTime: number = new Date().getTime();
  _suspended: boolean = false;

  _angleTextures: WebGLTexture[] = [];

  _previousFrame: WebGLTexture | null = null;
  _currentFrame: WebGLTexture | null = null;

  constructor(canvas: HTMLCanvasElement | OffscreenCanvas | null) {
    // Checking if webgl context is available
    if (!canvas) throw new Error('Could not find canvas element!');
    if (!canvas.getContext('webgl')) throw new Error('WebGL could not be initialised.');

    this.gl = canvas.getContext('webgl', {
      desynchronized: true,
      powerPreference: 'high-performance',
    });
    this.canvas = canvas;
    // Checking if context is generated
    if (!this.gl) throw new Error('WebGL context could not be initialised.');

    // Disabling color blending
    this.gl.disable(this.gl.BLEND);
  }

  load(shaders?: any): OpenGL | null {
    if (!this.gl) throw new Error('WebGL context not found!');

    // Generating program info
    this.programInfo = ProgramInfo(this.gl, 'PART', shaders);
    this.environmentProgramInfo = ProgramInfo(this.gl, 'ENVIRONMENT', shaders);
    this.displayProgramInfo = ProgramInfo(this.gl, 'DISPLAY', shaders);

    // Generating buffers
    this.buffers = initBuffers(this.gl);

    // Generating background wall
    this._wall = new Texture(this.gl, `${process.env.REACT_APP_STATIC}/images/environment/default/160/wall.png`, 1);

    // Generating shadow and floor
    this._shadow = new Part(this.gl, '__SHADOW__', 'small/shadow/000.jpg', { x: 0, y: 0, width: 1, height: 1 }, -1);
    this._mask = new Part(this.gl, '__MASK__', 'small/mask/000.jpg', { x: 0, y: 0, width: 1, height: 1 }, -4);

    // Setting default texture for shadow
    this._floor = new Part(this.gl, '__FLOOR__', 'small/floor/000.jpg', { x: 0, y: 0, width: 1, height: 1 }, -3);

    return this;
  }

  setMask(url: string, angle: number) {
    if (!this.gl) throw new Error('webGL context was not found');
    if (!url) throw new Error('Please send valid url');
    const gl: WebGLRenderingContext = this.gl;
    return new Promise((res, rej) => {
      this._mask?.addTextureToAngle(url, angle).then((_) => res(url));
    });
  }
  setWall(url: string) {
    if (!this.gl) throw new Error('webGL context was not found');
    if (!url) throw new Error('Please send valid url');
    const gl: WebGLRenderingContext = this.gl;

    return new Promise((res, rej) => {
      this._wall = new Texture(gl, url, 1, undefined, () => {
        res();
      });
    });
  }

  addShadow(url: string, angle: number) {
    return new Promise((res, rej) => {
      this._shadowSet = true;
      this._shadow?.addTextureToAngle(url, angle).then(() => res(url));
    });
  }

  getShadowAtAngle(angle: number) {
    return this._shadow?.getAngle(angle);
  }

  addFloor(url: string, angle: number) {
    return new Promise((res, rej) => {
      this._floorSet = true;
      this._floor?.addTextureToAngle(url, angle).then((_) => res(url));
    });
  }

  getFloorAtAngle(angle: number) {
    return this._floor?.getAngle(angle);
  }

  addFloorLight(url: string): OpenGL {
    if (!this.gl) throw new Error('webGL context was not found');
    this._floorLightSet = true;
    this._floorLight = new Texture(this.gl, url, 0);

    return this;
  }

  get wall(): Texture | null {
    return this._wall;
  }
  get floor(): Part | null {
    return this._floor;
  }
  get shadow(): Part | null {
    return this._shadow;
  }

  get parts(): Part[] {
    return this._parts;
  }
  get angle(): number {
    return this._angle;
  }

  get isSuspended(): boolean {
    return this._suspended;
  }

  setAngle(angle: number): OpenGL {
    this._angle = angle;
    return this;
  }

  setFloorPosition(position: Positioning): OpenGL {
    this._floor?.setPosition(position);

    return this;
  }

  setShadowPosition(position: Positioning): OpenGL {
    this._shadow?.setPosition(position);

    return this;
  }

  // PARTS
  addPart(key: string, initialUrl: string, layerIndex: number, positioning: Positioning, isTemplate?: boolean): Part {
    if (!this.gl) throw new Error('WebGL context not found!');
    if (layerIndex < 0)
      throw new Error(
        'Layer index must not be negative. Are you trying to update the floor or shadow? Use setShadowForAngle or setFloorForAngle methods.',
      );

    const index: number = this._parts.findIndex((part: Part) => part._key === key);
    const part: Part = new Part(this.gl, key, initialUrl, positioning, layerIndex, isTemplate);

    if (this._parts[index]) {
      const _part = this._parts[index];
      _part.setUrl(initialUrl);
      return _part;
    } else this._parts.push(part);

    return part;
  }

  getPart(key: string): Part | null {
    return this._parts.find((_) => _._key === key) || null;
  }

  get playStatus(): boolean {
    return this._playing;
  }

  _renderStarted: boolean = false;
  start(): OpenGL {
    if (this._renderStarted) throw new Error('Rendering has already been started');
    this.render();
    return this;
  }

  play(direction?: number): OpenGL {
    this._suspended = false;
    this._playing = true;
    if (direction) this._direction = direction;

    return this;
  }

  suspend(isSuspended: boolean = true): OpenGL {
    this._suspended = isSuspended;
    if (isSuspended) this.pause();

    return this;
  }

  pause(): OpenGL {
    this._playing = false;
    return this;
  }

  stop(): OpenGL {
    this._playing = false;
    this._angle = 0;
    return this;
  }

  next(): OpenGL {
    if (this._transition < 1) return this;
    this._angle = (this._angle + 1) % 8;
    this.displayTexture(this._angleTextures[this._angle]);
    return this;
  }
  previous(): OpenGL {
    if (this._transition < 1) return this;
    this._angle = (8 + this._angle - 1) % 8;
    this.displayTexture(this._angleTextures[this._angle]);
    return this;
  }

  displayTexture(texture: WebGLTexture) {
    this._previousFrame = this._currentFrame;
    this._currentFrame = texture;
    this._transition = 0;
  }

  createTextureFromPartsForAngle(parts: (Part | undefined)[], angle: number) {
    return new Promise((res, rej) => {
      const gl: WebGLRenderingContext | null = this.gl;
      if (!gl) throw new Error('Could not find webgl rendering context');

      const environment = drawEnvironment(gl, this.environmentProgramInfo, this.buffers, {
        wall: this._wall?.texture || null,
        floor: this._floor?.getAngle(angle) || null,
        floorLight: this._floorLight || null,
        shadow: this._shadow?.getAngle(angle) || null,
        mask: this._mask?.getAngle(angle) || null,
      });
      const render = parts
        .filter((_) => !!_)
        .reduce(
          (prevValue: WebGLTexture | null, part: Part | undefined) =>
            drawPart(
              gl,
              this.programInfo,
              this.buffers,
              prevValue,
              null,
              part?.getAngle(angle) || null,
              part?.getBaseAngle(angle) || null,
              this._mask?.getAngle(angle) || null,
              part?._templateValues,
            ),
          environment,
        );

      this._currentFrame = render;

      gl.useProgram(this.displayProgramInfo.program);
      res(true);
    });
  }

  // gl: WebGLRenderingContext, programInfo: IProgramInfo, buffers: any, floor: Part | null, wall: Texture | null, parts: Part[]
  render(angle?: number, isFeatured?: boolean) {
    return new Promise((res, rej) => {
      const gl: WebGLRenderingContext | null = this.gl;

      if (!gl) throw new Error('Could not find webgl rendering context');
      if (!this._shadowSet) throw new Error('Shadow has not been set!');
      if (!this._floorSet) throw new Error('Floor has not been set!');
      if (!this._floorLightSet) throw new Error('Floorlight has not been set!');

      // Transition value
      gl.uniform1f(gl.getUniformLocation(this.displayProgramInfo?.program, `uTransition`), this._transition);
      gl.uniform1i(gl.getUniformLocation(this.displayProgramInfo?.program, `iIsFeatured`), isFeatured ? 1 : 0);

      gl.uniform1i(gl.getUniformLocation(this.displayProgramInfo?.program, `uCurrentFrame`), 1);
      gl.activeTexture(gl.TEXTURE1);
      gl.bindTexture(gl.TEXTURE_2D, this._currentFrame);

      gl.uniform1i(gl.getUniformLocation(this.displayProgramInfo?.program, `uPreviousFrame`), 2);
      gl.activeTexture(gl.TEXTURE2);
      gl.bindTexture(gl.TEXTURE_2D, this._previousFrame || this._currentFrame);

      gl.uniform1i(gl.getUniformLocation(this.displayProgramInfo?.program, `uMaskTexture`), 3);
      gl.activeTexture(gl.TEXTURE3);
      gl.bindTexture(
        gl.TEXTURE_2D,
        this._mask?.getAngle(angle || 0)?.texture || this._previousFrame || this._currentFrame || null,
      );

      draw(this.gl, this.displayProgramInfo, this.buffers);

      res(true);
    });
  }
}
