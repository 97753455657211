import React, { useState } from 'react'
import Part from './Part';
import { useFurniture } from 'Providers/furniture';
import { useFurnitureState } from 'Providers/state';
import ErrorWidget from '../ErrorWidget';
import Close from './Close';
import Handler from './Handler';

const Index = React.memo(() => {
  const { furniture } = useFurniture();
  const { state, model } = useFurnitureState();
  const [open, setOpen] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const _handlePartToggle = id => setOpen(open === id ? null : id);

  return (
    <>
      <ErrorWidget>
        <Handler {...{ isCollapsed, setIsCollapsed }} />
      </ErrorWidget>  
      <div className={`attributes-wrapper ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="furniture-title">
          <h1 className="furniture-name">{ furniture?.name }</h1>
          <h2 className="model-name">{ model?.name }</h2>
          {/* <ErrorWidget>
            <Close {...{ isCollapsed, setIsCollapsed }} />
          </ErrorWidget>   */}
        </div>
        <div className="part-list">
          <ErrorWidget>
            {
              furniture?.attributes?.filter(_ => _.id !== 'version').filter(_ => !_.hidden).map((attribute, i) => <Part 
              { ...attribute } 
              key={`attribute-${attribute.id}-${i}`} 
              title={attribute.name} 
              togglePart={_handlePartToggle}
              active={open === attribute.id}
              state={state}
              partState={state[attribute.id]}
              partDisabled={state.disabled?.[attribute.id]?.materialUnits}
              />)
            }
          </ErrorWidget>
        </div>  
      </div>
    </>
  )
});

export default Index;
