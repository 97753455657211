import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from 'fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

const cache = new InMemoryCache({ fragmentMatcher });

export default ({ token, endpoint }) => {
  // Generating fetch link
  const httpLink = createHttpLink({ uri: `${process.env.REACT_APP_ENDPOINT}/graphql` });

  // Generating authorized link
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token,
        'X-Content': 'released',
      },
    };
  });

  // Generating client
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });

  return client;
};
