import React from 'react';

const Close = React.memo(({ setIsCollapsed }) => {
  const _handleClick = () => setIsCollapsed(true);
  return (
    <div onClick={_handleClick} className="sidebar-close-wrapper">
      <img src="/arrowDown.png" alt='dropdown' className="dropdown"/>
    </div>
  )
});

export default Close;