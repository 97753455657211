import React from 'react'
import Unit from './Unit';
import { unitComparer } from 'core';

const Units = React.memo(({ units = [], active, part, open, group }) => {
  return (
    <div className={`units-wrapper ${open ? 'open' : 'closed'}`}>
      {
        units?.filter(unit => unit.status !== 'hidden')?.map(unit => <Unit { ...unit } key={`${part}-${unit.id}`} group={group} active={unitComparer(unit.id, active)} part={part} />)
      }
    </div>
  )
});

export default Units;
