import React from 'react';
import { ApolloProvider } from "react-apollo";

import client from './client';
import { useEnvironment } from '../environment';


export default ({ children }) => {
  const { token, endpoint } = useEnvironment();
  return  (
    <ApolloProvider client={client({ token, endpoint })}>
      { children }
    </ApolloProvider>
  )
};