import React from 'react'
import { useFurniture } from 'Providers/furniture';
import { useFurnitureState } from 'Providers/state';

const Details = React.memo(() => {
  const { furniture } = useFurniture();
  const { model } = useFurnitureState();

  return (
    <div className='details-wrapper'>
      <h1>{ furniture?.name }</h1>
      <h2>{ model?.name }</h2>
    </div>
  )
})

export default Details;
