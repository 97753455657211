import IProgramInfo from './types/programinfo';

const VIEWPORT_WIDTH: number = 2048.0 * 2;
const VIEWPORT_HEIGHT: number = 1536.0 * 2;

const cache: any = {};

export interface Positioning {
  x: number;
  y: number;
  width: number;
  height: number;
}

const BlobToImageData = (blob: any) => {
  let blobUrl = URL.createObjectURL(blob);

  return new Promise((resolve, reject) => {
    let img: any = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err: any) => reject(err);
    img.src = blobUrl;
  });
  // }).then((img: any) => {
  //   URL.revokeObjectURL(blobUrl);
  //   // Limit to 256x256px while preserving aspect ratio
  //   let [w, h] = [img.width, img.height];
  //   let aspectRatio = w / h;
  //   // Say the file is 1920x1080
  //   // divide max(w,h) by 256 to get factor
  //   let factor = Math.max(w, h) / 256;
  //   // w = ;
  //   // h = ;

  //   console.log(w, h);

  //   // REMINDER
  //   // 256x256 = 65536 pixels with 4 channels (RGBA) = 262144 data points for each image
  //   // Data is encoded as Uint8ClampedArray with BYTES_PER_ELEMENT = 1
  //   // So each images = 262144bytes
  //   // 1000 images = 260Mb
  //   let canvas = document.createElement('canvas');
  //   canvas.width = w;
  //   canvas.height = h;
  //   let ctx: any = canvas.getContext('2d');
  //   ctx.drawImage(img, 0, 0);

  //   document.body.appendChild(canvas);

  //   return ctx.getImageData(0, 0, w, h); // some browsers synchronously decode image here
  // });
};

export default class Texture {
  static textureCount: number = 0;
  _url: string;
  _gl: WebGLRenderingContext;
  _image: HTMLImageElement | null = null;

  _index: number = 0;
  _isPart: boolean = false;
  _uniformIndex: number = 0;

  _x: number = 0;
  _y: number = 0;
  _width: number = 0;
  _height: number = 0;

  texture: WebGLTexture | null = null;

  level: number;
  internalFormat: number;
  width: number;
  height: number;
  border: number;
  srcFormat: number;
  srcType: number;
  pixel: Uint8Array;

  _onLoad: (url?: string, texture?: WebGLTexture | null) => any = () => null;

  constructor(gl: WebGLRenderingContext, url: string, index?: number, positioning?: Positioning, onLoad?: () => any) {
    this._url = url;
    this._gl = gl;

    if (onLoad) this._onLoad = onLoad;

    // Default texture values
    this.level = 0;
    this.internalFormat = gl.RGBA;
    this.width = 1;
    this.height = 1;
    this.border = 0;
    this.srcFormat = gl.RGBA;
    this.srcType = gl.UNSIGNED_BYTE;
    this.pixel = new Uint8Array([0, 0, 0, 0]);

    if (index) this._index = index;
    this._uniformIndex = Texture.textureCount;
    Texture.textureCount++;

    if (positioning) {
      this._x = positioning.x;
      this._y = positioning.y;
      this._width = positioning.width;
      this._height = positioning.height;
    }

    if (cache[url]) this._image = cache[url];

    this.generateTexture();
  }

  setIndex(index: number): void {
    this._index = index;
  }

  generateTexture() {
    const gl: WebGLRenderingContext = this._gl;

    // Creating texture
    this.texture = gl.createTexture();
    if (!this.texture) throw new Error(`Failed to create texture for url: ${this._url}`);

    // Binding texture
    gl.bindTexture(gl.TEXTURE_2D, this.texture);

    // Default values
    gl.texImage2D(
      gl.TEXTURE_2D,
      this.level,
      this.internalFormat,
      this.width,
      this.height,
      this.border,
      this.srcFormat,
      this.srcType,
      this.pixel,
    );

    (async () => {
      try {
        const res = await fetch(this._url, { method: 'GET', cache: 'default', mode: 'cors' });
        const imgBlob = await res.blob();

        try {
          if (typeof createImageBitmap !== 'undefined') {
            const img = await createImageBitmap(imgBlob);
            this.imageLoaded(img);
          } else {
            // console.log('FETCHING');
            const img = await BlobToImageData(imgBlob);
            // console.log(img, this._url);
            this.imageLoaded(img);
          }
        } catch (error) {
          // console.log('FAILED TO FETCH', error);
          // console.log(error);
          console.log('ERROR', error);
          this._onLoad?.(this._url, this.getTextureId());
        }
      } catch (error) {
        console.log('ERROR', error);
        this._onLoad?.(this._url, this.getTextureId());
      }
    })();
  }

  update(image: HTMLImageElement) {
    const gl: WebGLRenderingContext = this._gl;
    gl.bindTexture(gl.TEXTURE_2D, this.texture);
    gl.texSubImage2D(gl.TEXTURE_2D, this.level, 0, 0, this.srcFormat, this.srcType, image);
  }

  setPosition(positioning: Positioning) {
    this._x = positioning.x;
    this._y = positioning.y;
    this._width = positioning.width;
    this._height = positioning.height;
  }

  imageLoaded = (image: any) => {
    const gl: WebGLRenderingContext = this._gl;

    cache[this._url] = image;

    gl.bindTexture(gl.TEXTURE_2D, this.texture);
    gl.texImage2D(gl.TEXTURE_2D, this.level, this.internalFormat, this.srcFormat, this.srcType, image);

    if (isPowerOfTwo(image.width) && isPowerOfTwo(image.height)) {
      gl.generateMipmap(gl.TEXTURE_2D);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    } else {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

      // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    }

    if (!this._width) this._width = image.width / VIEWPORT_WIDTH;
    if (!this._height) this._height = image.height / VIEWPORT_HEIGHT;

    if (typeof createImageBitmap !== 'undefined') {
      setTimeout(() => this._onLoad(this._url, this.texture), 50);
    } else {
      setTimeout(() => this._onLoad(this._url, this.texture), 480);
    }
    // gl.bindTexture(gl.TEXTURE_2D, 0);
  };

  bind = (program: WebGLShader) =>
    this._gl.uniform1i(this._gl.getUniformLocation(program, `uPartTexture`), this._index);
  draw = () => {
    // gl.uniform1i(programInfo.uniformLocations.uMask0, 1);  // texture unit 1

    this._gl.activeTexture(this.getTextureId());
    this._gl.bindTexture(this._gl.TEXTURE_2D, this.texture);
  };

  getTextureId = (): number => {
    switch (this._index) {
      case 0:
        return this._gl.TEXTURE0;
      case 1:
        return this._gl.TEXTURE1;
      case 2:
        return this._gl.TEXTURE2;
      case 3:
        return this._gl.TEXTURE3;
      case 4:
        return this._gl.TEXTURE4;
      case 5:
        return this._gl.TEXTURE5;
      case 6:
        return this._gl.TEXTURE6;
      case 7:
        return this._gl.TEXTURE7;
      case 8:
        return this._gl.TEXTURE8;
      case 9:
        return this._gl.TEXTURE9;
      case 10:
        return this._gl.TEXTURE10;

      default:
        return this._gl.TEXTURE0;
    }
  };

  get position(): number[] | null {
    if (!this._x) return null;
    return [this._x, this._y, this._width, this._height];
  }

  bindPosition(gl: WebGLRenderingContext, programInfo: IProgramInfo) {
    gl.uniform4f(
      programInfo.uniformLocations.uPartPosition,
      this.position?.[0] || 0,
      this.position?.[1] || 0,
      this.position?.[2] || 1,
      this.position?.[3] || 1,
    );
  }

  static generatePositions(gl: WebGLRenderingContext, programInfo: IProgramInfo, textures: Texture[]): void {
    const res = textures.reduce(
      (acc: number[], texture: Texture) => (!texture?.position ? acc : [...acc, ...texture?.position]),
      [],
    );
    // gl.uniform4fv(programInfo.uniformLocations.uPartPositions, res.map((value, index) => value / (index % 2 === 0 ? VIEWPORT_WIDTH : VIEWPORT_HEIGHT)));  // texture unit 0
    gl.uniform4fv(programInfo.uniformLocations.uPartPositions, res); // texture unit 0
  }
  static bindTextures(gl: WebGLRenderingContext, programInfo: IProgramInfo, textures: Texture[]): void {
    const _textureSorter = (a: Texture, b: Texture): number => {
      if (a?._index > b?._index) return 1;
      if (a?._index < b?._index) return -1;
      return 0;
    };

    textures.sort(_textureSorter).map((texture) => texture?.bind(programInfo?.program));
  }
}

const isPowerOfTwo = (value: number): boolean => (value & (value - 1)) === 0;
