import React, { useRef, useCallback } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { Viewer, Attributes, ErrorWidget } from 'components';

const Furniture = () => {
  const image = useRef<HTMLDivElement>(null);
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = image;

    if (img && img !== undefined) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    <ErrorWidget>
      <div className='furniture-wrapper'>
        {/* <QuickPinchZoom onUpdate={onUpdate}> */}
        <div className='furniture' ref={image}>
          <Viewer />
        </div>
        {/* </QuickPinchZoom> */}
        <Attributes />
      </div>
    </ErrorWidget>
  );
};

export default Furniture;
