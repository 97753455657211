import React from 'react'
import Providers from 'Providers';
import Furniture from './Furniture';

export { default as Unauthorized } from './Unauthorized'

const Index = () => {
  return (
    <div className='screen-wrapper'>
      <Providers>
        <Furniture />
      </Providers>
    </div>
  )
}

export default Index;
