import React, { useState, useEffect } from 'react';

const Selector = React.memo(({ options = [], value, onChange = () => {}, name, onPureChange = () => {} }) => {
  const _handleSelect = (value) => {
    onChange({ name, value });
    onPureChange(value);
    _handleHide();
  };

  useEffect(() => {
    return () => window.removeEventListener('click', _handleHide, true);
  }, []);

  const [visible, setVisible] = useState(false);

  const _handleOpen = (e) => {
    e.stopPropagation();

    if (visible) _handleHide();
    else {
      setVisible(true);
      window.addEventListener('click', _handleHide, false);
    }
  };
  const _handleHide = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setVisible(false);
    window.removeEventListener('click', _handleHide, false);
  };

  const selected = options?.find((option) => option.value === value);

  return (
    <div className='types-outer-wrapper'>
      <div className={`types-wrapper ${visible ? 'visible' : ''}`} onClick={_handleOpen}>
        <span className='label'>{selected?.label || 'All types'}</span>
        <img src='/dropdown.svg' alt='dropdown' className='dropdown' />
      </div>
      <Dropdown
        options={[{ label: 'All types', value: null }, ...options]}
        onSelect={_handleSelect}
        value={value}
        visible={visible}
      />
    </div>
  );
});

export default Selector;

const Dropdown = ({ options, onSelect, value, visible }) => {
  return (
    <div className={`types-dropdown-wrapper ${visible ? 'visible' : 'hidden'}`}>
      {options.map((option) => (
        <Option {...option} active={value === option.value} onSelect={onSelect} key={`select-option-${option.value}`} />
      ))}
    </div>
  );
};

const Option = ({ value, label, onSelect, active }) => {
  const _handleSelect = () => onSelect(value);
  return (
    <div className={`select-option-wrapper ${active ? 'active' : ''}`} onClick={_handleSelect}>
      <span className='value'>{label.toLowerCase()}</span>
    </div>
  );
};
