import React, { useState } from 'react';
import Loading from './Loading';

interface ILoaderProps {
  filled: boolean;
  visible: boolean;
  banner?: string;
}
const Loader = ({ filled, visible, banner }: ILoaderProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const _handleLoad = () => setIsLoaded(true);
  return (
    <div className={`viewer-loader-wrapper ${visible ? 'visible' : 'hidden'}`}>
      <img className='loader' src='/loader.png' alt='loading indicator' />
      <h1>We are creating an amazing beam.image for you.</h1>
      <h1>Please wait a moment, it will be worth it.</h1>
    </div>
  );
};

export default Loader;
