import gql from 'graphql-tag';

export const furniture = gql`
  query GetFurniture($furniture: ID!) {
    viewer(furniture: $furniture) {
      furniture {
        id
        name
        brand {
          id
          name
          featured {
            key
            url
          }
        }
        defaultModel {
          featured {
            url
          }
        }
        description
      }
      attributes {
        id
        name
        key
        type
        options
        hidden
        groups: materialGroups {
          id
          name
          units: materialUnits {
            status
            id
            key
            name
            featured {
              key
              id
              url
            }
            attachments {
              key
              role
              url
            }
            types
            isTemplate
            templateKey
            baseColor {
              r
              g
              b
            }
            notBaseColor {
              r
              g
              b
            }
          }
        }
        units: materialUnitsExtended {
          status
          id
          key
          name
          featured {
            key
            url
            id
          }
          types
          isTemplate
          templateKey
          baseColor {
            r
            g
            b
          }
          notBaseColor {
            r
            g
            b
          }
        }
      }
    }
  }
`;

export const state = `query Query($furniture: ID! $state: JSON) {
  viewer(furniture: $furniture, state: $state) {
    state
    specials
    favorites {
      __typename
      id
      state
      name
    }
    model {
      id
      name
      size
      startingFrom
      startingAngle
      measurements {
        __typename
        view
        axis
        origin
        value
      }
      attachments {
        __typename
        key
        id
        name
        size
        role
      }
      canBeOnFloor
      canBeOnCeiling
      canBeOnWall
      canBeOnOther
    }
  }
}
`;
