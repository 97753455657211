export default (gl, vsSource, fsSource, key = 'unnamed') => {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource, key);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource, key);

  const shaderProgram = gl.createProgram();
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS))
    throw new Error(`Unable to initialize the shader program: ${gl.getProgramInfoLog(shaderProgram)}`);

  return shaderProgram;
};

const loadShader = (gl, type, source, key) => {
  const shader = gl.createShader(type);

  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    const log = gl.getShaderInfoLog(shader);
    gl.deleteShader(shader);
    throw new Error(`An error occurred compiling the ${key} shader: ${log}`);
  }

  return shader;
};
