import React from 'react';
import Units from './Units';

const Group = React.memo(({ name, units, active, part, partName, open, partDisabled, unitType }) => {
  const materialUnits = units?.filter(unit => !unitType || unit.types.includes(unitType)).filter(unit => !partDisabled?.includes(unit.id));
  if (materialUnits.length === 0) return null;
  return (
    <div className="unit-group">
      <span className="group-label">{ name }</span>
      <Units { ...{ units: materialUnits, group: name, active, part, open }} />
    </div>
  )
});

export default Group;
