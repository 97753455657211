import React from 'react';

import ApolloProvider from './apollo'
import EnvironmentProvider from './environment'
import FurnitureProvider from './furniture'
import StateProvider from './state'

const index = ({ children }) => {
  return (
    <EnvironmentProvider>
      <ApolloProvider>
        <FurnitureProvider>
          <StateProvider>
            { children }
          </StateProvider>
        </FurnitureProvider>
      </ApolloProvider>
    </EnvironmentProvider>
  )
}

export default index;
