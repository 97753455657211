import initShaderProgram from '../shader.js';
import vsSource from '../shaders/vertex.js';
import displayFsSource from '../shaders/display.js';
import partFsSource from '../shaders/part.js';
// import environemntFsSource from '../shaders/environment.js';

import { crypt } from '../../';
import IProgramInfo from '../types/programinfo';

export default (gl: WebGLRenderingContext, type: string, shaders?: any): IProgramInfo => {
  // @ts-ignore
  const shader = crypt.handleDecrypt(shaders?.[type.toLowerCase()] || '');
  const program = initShaderProgram(
    gl,
    vsSource,
    type === 'PART' && process.env.NODE_ENV === 'development'
      ? partFsSource
      : type === 'DISPLAY' && process.env.NODE_ENV === 'development'
      ? displayFsSource
      : shader,
    // shader,
    type,
  );

  return {
    program,
    isEnvironment: type === 'ENVIRONMENT',
    attribLocations: {
      vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      textureCoord: gl.getAttribLocation(program, 'aTextureCoord'),
    },
    uniformLocations: {
      uTexture0: gl.getUniformLocation(program, 'uTexture0'),
      uTexture1: gl.getUniformLocation(program, 'uTexture1'),
      uTexture2: gl.getUniformLocation(program, 'uTexture2'),
      uTexture3: gl.getUniformLocation(program, 'uTexture3'),
      uTexture4: gl.getUniformLocation(program, 'uTexture4'),
      uTexture5: gl.getUniformLocation(program, 'uTexture5'),
      uTexture6: gl.getUniformLocation(program, 'uTexture6'),
      uTexture7: gl.getUniformLocation(program, 'uTexture7'),
      uTexture8: gl.getUniformLocation(program, 'uTexture8'),
      uTexture9: gl.getUniformLocation(program, 'uTexture9'),

      uPartPositions: gl.getUniformLocation(program, 'uPartPositions'),
      uPartPosition: gl.getUniformLocation(program, 'uPartPosition'),
      uShadowPosition: gl.getUniformLocation(program, 'uShadowPosition'),

      iIsFeatured: gl.getUniformLocation(program, 'iIsFeatured'),
    },
  };
};
