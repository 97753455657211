import React from 'react';

interface ILoaderProps {
  visible: boolean;
}
const Loader = ({ visible }: ILoaderProps) => {
  return (
    <div className={`viewer-loading-overlay ${visible ? 'visible' : 'hidden'}`}>
      <img src='/loader.png' alt='loading indicator' />
    </div>
  );
};

export default Loader;
