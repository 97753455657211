import React from 'react';

const Handler = React.memo(({ isCollapsed, setIsCollapsed }) => {
  const _handleAction = () => setIsCollapsed(!isCollapsed);
  return (
    <div onClick={_handleAction} className={`sidebar-handler-wrapper ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="handler-top"></div>
      <div className="handler-bottom"></div>
    </div>
  )
});

export default Handler;