import React, { useRef, useEffect, useState } from 'react';
import { ViewerManager } from 'core';
import { useFurnitureState } from 'Providers/state';
import { useFurniture } from 'Providers/furniture';
import equal from 'deep-equal';
import Loader from './Loader';
import Loading from './Loading';
import ErrorWidget from '../ErrorWidget';
import Brand from '../Brand';

import handleAngle from './helpers/angle';
import setupScene from './helpers/scene';

import Worker from '../../worker';

const order = [0, 1, -1, 2, 3, 4, -3, -2];

let previousState: any = {};

let killCode: number;
let kills: number[] = [];

let renders = -1;

const timeWindow = 550;
let lastExecution = new Date(new Date().getTime() - timeWindow);

if (typeof OffscreenCanvas === 'undefined') var OffscreenCanvas: any = undefined;

class WorkerManager {
  _worker: Worker;
  _loading: Boolean;
  _queue: any[];
  _currentResolve: Function | null;
  _canvasLoaded: Boolean = false;

  constructor(worker: Worker) {
    this._worker = worker;
    this._loading = false;
    this._queue = [];
    this._currentResolve = null;

    this._worker.onmessage = this.handleMessage;
  }

  handleMessage = (msg: any) => {
    if (msg?.data?.action === 'SCENE_READY') this._loading = false;
    else if (msg?.data?.action === 'ANGLE_DONE') {
      this._loading = false;
      if (this._currentResolve) this._currentResolve(msg);
    } else if (msg?.data?.action === 'CANVAS_RECEIVED' && this._currentResolve) this._currentResolve(msg);
    this.pull();
  };

  render = (args: any): any => {
    return new Promise((resolve, reject) => {
      this._queue.push({ args, resolve });
      this.pull();
    });
  };

  pull = (): any => {
    if (this._loading || this._queue.length === 0) return false;
    const job = this._queue.shift();
    this._currentResolve = job.resolve;
    this._worker.postMessage(job.args, job.ref);
    this._loading = true;
  };

  setScene = (scene: any) => {
    this._loading = true;
    this._worker.postMessage(scene);
  };

  setCanvas = (canvas: any, isFeatured: boolean, shaders: any) =>
    new Promise((resolve, rej) => {
      if (this._canvasLoaded) return resolve();
      this._queue.push({
        args: { action: 'CANVAS', canvas, isFeatured, shaders },
        ref: [canvas],
        resolve: () => {
          this._canvasLoaded = true;
          resolve();
        },
      });
      this.pull();
    });
}

// const workerRef = useRef<Worker>(new Worker('/static/js/worker.js'));
const workerRef = { current: new Worker('/static/js/worker.js') };
const workerManager = { current: new WorkerManager(workerRef.current) };

let _loaded: any = {};
const Viewer = () => {
  const canvasHolder = useRef<HTMLCanvasElement | null>(null);
  const { current: currentCanvas } = useRef<OffscreenCanvas | HTMLCanvasElement | null>(
    OffscreenCanvas ? new OffscreenCanvas(4096, 3072) : null,
  );
  const _offscreenCanvas = useRef<any>(null);
  const [viewer, setViewer] = useState<ViewerManager | null>(null);
  const [isViewerLoading, setIsLoading] = useState<boolean>(true);
  const [angles, setAngles] = useState<{
    current: number;
    previous: number | null;
  }>({ current: 0, previous: null });
  const _angle = useRef<number>(0);

  useEffect(() => {
    _angle.current = angles.current;
  }, [angles]);
  const { model, state: furnitureState, offline, isLoading, finishLoading, isFeatured } = useFurnitureState();
  const { furniture } = useFurniture();
  const [isLoaded, setIsLoaded] = useState<any>({});

  let canvas: any;

  const _canvas = useRef<HTMLCanvasElement | null>(null);
  const canvases = [
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
    useRef<HTMLCanvasElement | null>(null),
  ];

  const loadingStates = [
    useState<boolean>(false),
    useState<boolean>(false),
    useState<boolean>(false),
    useState<boolean>(false),
    useState<boolean>(false),
    useState<boolean>(false),
    useState<boolean>(false),
    useState<boolean>(false),
  ];

  // Generate furniture state from attributes and state received from server
  const attributes = furniture?.attributes || [];
  const state: any = {
    model: model?.id,
    startingAngle: model?.startingAngle || 0,
    size: model?.size || 160,
    offline: offline || false,
    parts: furnitureState
      ? Object.keys(furnitureState)?.reduce((prevValue: any, key: string) => {
          const attribute = attributes.find((attr: any) => attr.id === key);

          if (attribute?.type !== 'material' || !attribute?.key) return prevValue;
          const part = attribute.units.find((unit: any) => unit.id === furnitureState[key]);

          console.log(attribute.key, part);
          return { ...prevValue, [attribute.key]: part };
        }, {})
      : [],
  };

  useEffect(() => {
    try {
      const canvas = _canvas.current;
      _offscreenCanvas.current = canvas?.transferControlToOffscreen();
    } catch (error) {}
  }, []);

  if (OffscreenCanvas) canvas = { current: currentCanvas };
  else canvas = canvasHolder;

  const _handleGenerate = () => {
    const currentAngle = _angle.current;
    if (!viewer) return () => {};
    if (equal(state, previousState)) return;
    previousState = state;

    if (killCode) kills.push(killCode);

    if (state.offline) return;

    if (model?.id && Object.keys(state.parts).length > 0) {
      (async () => {
        for (let i = 0; i < 8; i++) {
          loadingStates[i][1](true);
        }
        let parts;
        if (_offscreenCanvas.current) {
          // @ts-ignore: Unreachable code error
          await workerManager?.current.setCanvas(_offscreenCanvas.current, isFeatured, window.hlsl);
          workerManager?.current.setScene({ action: 'SCENE', state, model, isFeatured });
        } else {
          parts = await setupScene(viewer, state, model);
        }

        setIsLoaded({ [angles.current]: true });
        _loaded = {};

        killCode = Math.random();
        let currentKillCode = killCode;
        renders++;
        for (let i = 0; i < 8; i++) {
          const delta = order[i];
          const angle = (8 + delta + currentAngle) % 8;

          if (kills.includes(currentKillCode)) return;

          let image: any;
          if (_offscreenCanvas.current) {
            const e = await workerManager?.current.render({
              action: 'ANGLE',
              angle,
              index: i,
            });
            image = e.data?.imageBitmap;
          } else {
            await handleAngle(viewer, state, parts, angle, model, isFeatured);
            image = canvas?.current;
          }
          await (() =>
            new Promise((res, rej) => {
              loadingStates[angle][1](true);
              setTimeout(() => {
                if (!canvases) return;
                if (!canvases[angle]) return;
                if (!canvases[angle].current) return;
                if (!canvas.current) return;

                if (kills.includes(currentKillCode)) return;

                if (angles.current === angle && renders > 0) {
                  const ctx = canvases[8]?.current?.getContext('2d');
                  let currentVisibleCanvas = canvases[angle]?.current;

                  if (currentVisibleCanvas) ctx?.drawImage(currentVisibleCanvas, 0, 0);
                  if (kills.includes(currentKillCode)) return;

                  canvases[8]?.current?.classList.remove('image-old');
                  // CSS animation reset does not work without this
                  void canvases[8]?.current?.offsetWidth;
                  canvases[8]?.current?.classList.add('image-old');
                  if (!canvases[8] || !canvases[8].current) return;
                }

                if (kills.includes(currentKillCode)) return;

                setTimeout(() => {
                  const ctx = canvases[angle]?.current?.getContext('2d');
                  if (ctx) ctx.drawImage(image, 0, 0);
                  loadingStates[angle][1](false);

                  res();
                }, 0);

                _loaded[angle] = true;
                setIsLoaded({ ..._loaded });

                if (angles.current === angle) {
                  finishLoading();
                  setIsLoading(false);
                }
              }, 0);
            }))();
        }
      })();
    }
  };
  useEffect(() => {
    _handleGenerate();
  }, [state]);

  const _handleNext = () => {
    const _handleKey = () => {
      if (isLoaded[angles.current]) {
        setAngles({
          previous: angles.current,
          current: (8 + angles.current + 1) % 8,
        });
      }
    };

    return () => {
      if (lastExecution.getTime() + timeWindow <= new Date().getTime()) {
        lastExecution = new Date();
        return _handleKey();
      }
    };
  };
  const _handlePrev = () => {
    const _handleKey = () => {
      if (isLoaded[angles.current]) {
        setAngles({
          previous: angles.current,
          current: (8 + angles.current - 1) % 8,
        });
      }
    };

    return () => {
      if (lastExecution.getTime() + timeWindow <= new Date().getTime()) {
        lastExecution = new Date();
        return _handleKey();
      }
    };
  };

  const _handleKey = (() => {
    const _handleKey = (ev: KeyboardEvent) => {
      if (ev.key === 'ArrowRight' && isLoaded[angles.current])
        setAngles({
          previous: angles.current,
          current: (8 + angles.current + 1) % 8,
        });
      if (ev.key === 'ArrowLeft')
        setAngles({
          previous: angles.current,
          current: (8 + angles.current - 1) % 8,
        });
    };

    return (ev: KeyboardEvent) => {
      if (lastExecution.getTime() + timeWindow <= new Date().getTime()) {
        lastExecution = new Date();
        return _handleKey(ev);
      }
    };
  })();

  useEffect(() => {
    const opengl = new ViewerManager(canvas.current)?.load((window as any).hlsl);

    setViewer(opengl);

    return () => window.removeEventListener('keydown', _handleKey);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', _handleKey);

    return () => window.removeEventListener('keydown', _handleKey);
  }, [angles, isLoaded]);

  const displayAngles = [angles.current, angles.previous];

  return (
    <div className='viewer-wrapper'>
      <Loading visible={isLoading || !isLoaded[angles.current]} />
      <div className='arrows-wrapper'>
        <div className='arrow left' onClick={_handlePrev()}>
          <img src='/rotate.png' alt='' />
        </div>
        <div className='arrow right' onClick={_handleNext()}>
          <img src='/rotate.png' alt='' />
        </div>
      </div>
      <div className='image-wrapper'>
        {process.env.NODE_ENV === 'development' && (
          <div style={{ position: 'absolute', top: 48, left: 48, background: 'white', zIndex: 1024 }}>
            {loadingStates.map((_, i) => (
              <div>
                {i + 1}. - {_[0] ? 'loading' : 'loaded'}
              </div>
            ))}
          </div>
        )}
        <canvas
          width={4096}
          height={3072}
          key='canvases[-1]'
          style={{ display: 'block', zIndex: 101 }}
          className={`image image-old`}
          ref={canvases[8]}
        />
        {new Array(8).fill(null).map((_, i) => (
          <canvas
            width={4096}
            height={3072}
            key={`canvases[${i}]`}
            style={{
              display: displayAngles.includes(i) && isLoaded[i] ? 'block' : 'none',
              zIndex: i === angles.current ? 100 : i === angles.previous ? 90 : 0,
            }}
            data-current={i === angles.current ? 1 : 0}
            // style={{ display: (displayAngles.includes(i) && isLoaded[i]) ? 'block' : 'none', zIndex: i === angles.current ? 100 : (i === angles.previous ? 90 : 0) }}
            // className={`image ${(i === angles.current && isLoaded[i]) ? 'overlay' : ''}`}
            className={`image ${i === angles.current ? 'overlay' : ''}`}
            ref={canvases[i]}
          />
        ))}

        <Loader filled visible={isViewerLoading} banner={furniture.defaultModel?.featured?.url} />
        <ErrorWidget>
          <Brand />
        </ErrorWidget>
      </div>
      <canvas width={4096} height={3072} ref={_canvas} style={{ display: 'none' }} />
      {!OffscreenCanvas && <canvas width={4096} height={3072} ref={canvasHolder} />}
    </div>
  );
};

export default Viewer;
