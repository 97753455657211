import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { furniture as GET_FURNITURE } from 'queries/furniture';
import { useEnvironment } from 'Providers/environment';

const Context = createContext();

export const withFurniture = Comp => props => <Context.Consumer>{(context) => <Comp { ...{ ...props, context}} />}</Context.Consumer>
export const useFurniture = () => useContext(Context);

const Index = ({ children }) => {
  const { furniture } = useEnvironment();
  const { data, loading, error } = useQuery(GET_FURNITURE, { variables: { furniture }});
  
  return (
    <Context.Provider value={{ furniture: { ...data?.viewer?.furniture, attributes: data?.viewer?.attributes }, loading, error }}>
      { children }
    </Context.Provider>
  )
}

export default Index;
