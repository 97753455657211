const ENDPOINT = process.env.REACT_APP_STATIC;

export const DEFAULT_TEMPLATE_IMAGE_URL =
  ENDPOINT + '/images/__MODEL__/__PART__/__MATERIAL__/__IMAGE__/4-__ANGLE__.jpg';
export const DEFAULT_IMAGE_URL = ENDPOINT + '/images/__MODEL__/__PART__/__MATERIAL__/4-__ANGLE__.jpg';
export const DEFAULT_SHADOW_URL = ENDPOINT + '/images/__MODEL__/shadow/4-__ANGLE__.jpg';
export const DEFAULT_JSON_URL = ENDPOINT + '/images/__MODEL__/__PART__/__MATERIAL__.json';
export const DEFAULT_TEMPLATE_JSON_URL = ENDPOINT + '/images/__MODEL__/__PART__/__MATERIAL__/data.json';
export const DEFAULT_FLOORLIGHT_IMAGE_URL = ENDPOINT + '/images/environment/default/__MODEL__/floorLight.png';
export const DEFAULT_FLOOR_IMAGE_URL = ENDPOINT + '/images/environment/default/__MODEL__/floor/__ANGLE__.jpg';
export const DEFAULT_WALL_IMAGE_URL = ENDPOINT + '/images/environment/default/__MODEL__/wall.png';
export const DEFAULT_MASK_IMAGE_URL = ENDPOINT + '/images/__MODEL__/mask/4-__ANGLE__.png';

export const generateUrl = (model: string, part: string, material: string, angle: number | string): string =>
  DEFAULT_IMAGE_URL.replace('__MODEL__', model)
    .replace('__PART__', part)
    .replace('__MATERIAL__', material)
    .replace('__ANGLE__', angle.toString());

export const generateTemplateUrl = (model: string, part: any, material: string, angle: number | string): string =>
  DEFAULT_TEMPLATE_IMAGE_URL.replace('__MODEL__', model)
    .replace('__PART__', part)
    .replace('__MATERIAL__', material)
    .replace('__ANGLE__', angle.toString());

export const generateJSONUrl = (model: string, part: any, material: string): string =>
  DEFAULT_JSON_URL.replace('__MODEL__', model).replace('__PART__', part).replace('__MATERIAL__', material);

export const generateTemplateJSONUrl = (model: string, part: any, material: string): string =>
  DEFAULT_TEMPLATE_JSON_URL.replace('__MODEL__', model).replace('__PART__', part).replace('__MATERIAL__', material);

export const generateShadowUrl = (model: string, angle: number | string): string =>
  DEFAULT_SHADOW_URL.replace('__MODEL__', model).replace('__ANGLE__', angle.toString());

export const generateMaskUrl = (model: string, angle: number | string): string =>
  DEFAULT_MASK_IMAGE_URL.replace('__MODEL__', model).replace('__ANGLE__', angle.toString());
