import React, { createContext, useState, useContext, useEffect } from 'react';
import { Unauthorized } from 'screens';
import { crypt } from '../../core';

const Context = createContext({});

export const withEnvironment = (Comp) => (props) => (
  <Context.Consumer>{(context) => <Comp {...{ ...props, context }} />}</Context.Consumer>
);
export const useEnvironment = () => useContext(Context);

const endpoint = process.env.REACT_APP_ENDPOINT;

const Index = ({ children }) => {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const _handleLoad = async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('t');
    const furniture = params.get('f');
    const initialAngle = params.get('a') || 30;
    const isFeatured = ![undefined, null].includes(params.get('featured'));
    setData({
      token,
      furniture,
      initialAngle,
      isFeatured,
      loaded: true,
    });
  };

  const _handleShader = async (key) => {
    const res = await fetch(`https://s3.eu-central-1.amazonaws.com/app.beam.space/${key}.txt`);
    const shader = await res.text();
    window.hlsl[key] = shader;
  };
  const _handleShaders = async () => {
    window.hlsl = {};
    await Promise.all([_handleShader('display'), _handleShader('environment'), _handleShader('part')]);
    requestAnimationFrame(() => {
      setLoaded(true);
    });
  };

  useEffect(() => {
    _handleShaders();
    _handleLoad();
  }, []);

  return (
    <Context.Provider value={{ ...data, endpoint }}>
      {data?.loaded && loaded && (data?.token ? children : <Unauthorized />)}
    </Context.Provider>
  );
};

export default Index;
