import React, { useRef, useState, useEffect } from 'react'
import Groups from './Groups';
import Title from './Title';
import Options from './Options';
import Types from './Types';

const Part = React.memo(({ title, units = [], groups, id, togglePart, active, partState, partDisabled, type, options, state, ...props }) => {
  const content = useRef(null);
  const [unitType, setType] = useState(null);
  const [height, setHeight] = useState(0);

  const types = Array.from(new Set(units?.filter(unit => !partDisabled?.includes(unit?.id)).reduce((acc, material) => {
    if (material?.types?.length > 0) return [...acc, ...material.types];
    return acc;
  }, []))).sort().map(type => ({ value: type, label: type }));

  useEffect(() => {
    if (!active) return setHeight(0);
    setHeight(content?.current?.scrollHeight);
  }, [unitType, active]);

  return (
    <div className='part-wrapper'>
      <Title { ...{ title, togglePart, id, options, partState, units, active }} />
      <div 
        className="dropdown-wrapper" 
        style={{ 
          height: `${height}px`,
          opacity: active ? 1 : 0,
        }}
      >
        <div
          className="content-wrapper"
          ref={content} 
        >
          { types.length > 0 && <Types options={types} onPureChange={setType} value={unitType} /> }
          {
            type === 'material'
              ? <Groups { ...{ groups, active: partState, part: id, open: active, partDisabled: state?.disabled?.[id].materialUnits, unitType, partName: title }} />
              : <Options { ... { options, active: partState, part: id, open: active }} />
          }

        </div>
      </div>
    </div>
  )
});

export default Part;
