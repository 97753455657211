import { generateTemplateUrl, generateUrl } from './urlGenerators';

const _handleScene = async (opengl: any, state: any, model: any) => {
  if (!model?.id) return console.log('missing model id');

  console.log('model', model);
  // Hardcoded backgorund positions
  opengl?.setFloorPosition({
    x: 0,
    y: 1102 / 1536,
    width: 1,
    height: (1536 - 1102) / 1536,
  });
  opengl?.setShadowPosition({ x: 0, y: 0, width: 1, height: 1 });
  await opengl?.setWall(`${process.env.REACT_APP_STATIC}/images/environment/default/${model.size}/wall.png`);

  const parts = Object.keys(state.parts)
    .map((partKey: string, partIndex: number) => {
      const part = state.parts[partKey];
      const url = part?.isTemplate
        ? generateTemplateUrl(model?.id, partKey, part?.templateKey, '__ANGLE__')
        : generateUrl(model?.id, partKey, state.parts[partKey]?.id, '__ANGLE__');

      return opengl?.addPart(partKey, url, partIndex, { x: 0, y: 0, width: 1, height: 1 }, part?.isTemplate);
    })
    .filter((_) => !!_);

  await Promise.all(
    parts.map((part) => {
      if (!state.parts[part?.key]) return null;
      return part.partLoad(model?.id, state.parts[part._key], state.parts[part._key]?.isTemplate);
    }),
  );

  return parts.filter((_) => !!_);
};

export default _handleScene;
