interface createBuffer {
  render: WebGLTexture,
  framebuffer: WebGLFramebuffer,
};

const VIEWPORT_WIDTH: number = 2048 * 2;
const VIEWPORT_HEIGHT: number = 1536 * 2;

export const bindFramebuffer = (gl: WebGLRenderingContext, fb: WebGLFramebuffer | null) => {
  gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
  gl.viewport(0, 0, VIEWPORT_WIDTH, VIEWPORT_HEIGHT);
}
export const unbindFrameBuffer = (gl: WebGLRenderingContext) => {
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
};

export const createFrameBuffer = (gl: WebGLRenderingContext): createBuffer => {
  const render: WebGLTexture | null = createTargetTexture(gl);
  const framebuffer: WebGLFramebuffer | null = gl.createFramebuffer();

  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
  const attachmentPoint = gl.COLOR_ATTACHMENT0;
  gl.framebufferTexture2D(gl.FRAMEBUFFER, attachmentPoint, gl.TEXTURE_2D, render, 0);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);

  if (!render || !framebuffer) throw new Error('Failed to create framebuffer');

  return {
    render,
    framebuffer,
  }
}

const createTargetTexture = (gl: WebGLRenderingContext): WebGLTexture | null => {
  const targetTexture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, targetTexture);

  // define size and format of level 0
  const level = 0;
  const internalFormat = gl.RGBA;
  const border = 0;
  const format = gl.RGBA;
  const type = gl.UNSIGNED_BYTE;
  const data = null;
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, VIEWPORT_WIDTH, VIEWPORT_HEIGHT, border, format, type, data);
  
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

  return targetTexture;
}