import React from 'react';
import { useFurniture } from 'Providers/furniture';
import { useFurnitureState } from 'Providers/state';

const Brand = React.memo(() => {
  const { furniture } = useFurniture();
  const { model } = useFurnitureState();

  if (!furniture || !furniture.brand) return null;

  const { brand: { featured: { url: featuredUrl }}, name } = furniture;
  return (
    <div className="furniture-brand-wrapper">
      <img className='brand' src={featuredUrl} alt={name} />
      <h1 className="furniture-name">{ name }</h1>
      <h2 className="model-name">{ model?.name }</h2>
    </div>
  )
});

export default Brand;