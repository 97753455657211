import React from 'react'
import { useFurnitureState } from 'Providers/state';

const Options = React.memo(({ options, part, active }) => {
  return (
    <div className='options-wrapper'>
      {
        options?.map(option => <Option label={option} key={option} part={part} active={option === active} />)
      }
    </div>
  )
});

const Option = React.memo(({ label, part, active }) => {
  const { changeMaterial } = useFurnitureState();
  const _handleSelect = () => changeMaterial(part, label);

  return (
    <div
      onClick={_handleSelect}
      className={`option ${active ? 'active' : 'inactive'}`}
    >
      <div className="radio" />
      <span>{ label }</span>
    </div>
  )
});

export default Options;
