import React from 'react'

const Unauthorized = () => {
  return (
    <div className='screen-wrapper'>
      <h1>401</h1>
    </div>
  )
}

export default Unauthorized;
