import Texture from './Texture';
import draw from './draw';
import { createFrameBuffer, bindFramebuffer, unbindFrameBuffer } from './helpers/framebuffer';

export default (
  gl: WebGLRenderingContext,
  programInfo: any,
  buffers: any,
  frame: WebGLTexture | null,
  shadow: WebGLTexture | null,
  part: Texture | null,
  partBase: Texture | null,
  mask: Texture | null,
  templateDetails?: any,
): WebGLTexture => {
  const { render, framebuffer } = createFrameBuffer(gl);

  // Switching to desired shader program
  gl.useProgram(programInfo?.program);

  gl.uniform1i(gl.getUniformLocation(programInfo?.program, 'uIsTemplate'), !!partBase ? 1 : 0);

  if (templateDetails) {
    gl.uniform1f(gl.getUniformLocation(programInfo?.program, 'uMaxBase'), templateDetails.maxBase);
    console.log('TEMP', templateDetails);
    gl.uniform1f(gl.getUniformLocation(programInfo?.program, 'uMaxNotBase'), templateDetails.maxNotBase);
    gl.uniform3fv(gl.getUniformLocation(programInfo?.program, 'uBaseColor'), templateDetails.baseColor);
    gl.uniform3fv(gl.getUniformLocation(programInfo?.program, 'uNotBaseColor'), templateDetails.notBaseColor);
  }
  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uFrameTexture`), 0);
  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, frame || null);

  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uShadowTexture`), 1);
  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, shadow || null);

  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uPartTexture`), 2);
  gl.activeTexture(gl.TEXTURE2);
  gl.bindTexture(gl.TEXTURE_2D, part?.texture || null);

  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uPartBaseTexture`), 3);
  gl.activeTexture(gl.TEXTURE3);
  gl.bindTexture(gl.TEXTURE_2D, partBase?.texture || part?.texture || null);

  // gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uPartBaseTexture`), 4);
  // gl.activeTexture(gl.TEXTURE4);
  // gl.bindTexture(gl.TEXTURE_2D, mask?.texture || null);

  // binding mask
  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uMaskTexture`), 5);
  gl.activeTexture(gl.TEXTURE5);
  gl.bindTexture(gl.TEXTURE_2D, mask?.texture || null);

  part?.bindPosition(gl, programInfo);

  bindFramebuffer(gl, framebuffer);
  draw(gl, programInfo, buffers);
  unbindFrameBuffer(gl);

  gl.deleteTexture(frame);
  gl.deleteFramebuffer(framebuffer);

  return render;
};
