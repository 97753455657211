import Texture, { Positioning } from './Texture';
import { generateJSONUrl, generateTemplateJSONUrl } from '../../components/Viewer/helpers/urlGenerators';

const cache: any = {};

interface ITemplateDetails {
  maxBase: number;
  maxNotBase: number;
  baseColor: number[];
  notBaseColor: number[];
}

if (typeof window === 'undefined') var window: any = null;
export default class Part {
  _angles: HTMLImageElement[] | null[] = new Array(360).fill(null);

  _large: Texture | null = null;
  _index: number = 0;
  _key: string | null = null;
  _positions: any;

  _texture: Texture | null = null;
  _textures: Texture[] = [];
  _baseTextures: Texture[] = [];
  _positioning: Positioning | null = null;

  _isTemplate: boolean = false;

  imageUrl: string = '';
  isTemplate: boolean = false;
  _gl: WebGLRenderingContext;

  _templateValues: ITemplateDetails | null = null;
  constructor(
    gl: WebGLRenderingContext,
    key: string,
    url: string,
    positioning: Positioning,
    index?: number,
    isTemplate?: boolean,
    templateValues?: ITemplateDetails,
  ) {
    this._gl = gl;
    if (index) this._index = index + 3;

    this.imageUrl = url;
    this.isTemplate = isTemplate || false;

    this._key = key;
    this._positioning = positioning;

    this._positions = {};

    if (templateValues) this._templateValues = templateValues;
  }

  get key(): string | null {
    return this._key;
  }

  setIsTemplate(isTemplate: boolean) {
    this._isTemplate = isTemplate;
  }

  setUrl(url: string) {
    this.imageUrl = url;
  }

  setPosition(position: Positioning) {
    this._positioning = position;
    // this._texture?.setPosition(position);
  }

  addTextureToAngle(url: string, angle: number, positioning?: Positioning) {
    return new Promise((res, rej) => {
      const gl = this._gl;
      if (!gl) throw new Error('Could not find opengl context');
      this._textures[angle] = new Texture(gl, url, this._index, positioning || this._positioning || undefined, res);
    });
  }

  addBaseTextureToAngle(url: string, angle: number, positioning?: Positioning, templateValues?: ITemplateDetails) {
    return new Promise((res, rej) => {
      this._isTemplate = true;
      const gl = this._gl;

      if (!gl) throw new Error('Could not find opengl context');

      this._baseTextures[angle] = new Texture(gl, url, this._index, positioning || this._positioning || undefined, res);

      if (templateValues) this._templateValues = templateValues;
    });
  }

  setTemplateDetails(templateValues: ITemplateDetails): void {
    this._templateValues = templateValues;
  }

  getAngle(angle: number, layerIndex?: number): Texture | null {
    if (this._textures[angle]) return this._textures[angle];
    return null;
  }

  getBaseAngle(angle: number, layerIndex?: number): Texture | null {
    if (!this._isTemplate) return null;
    if (this._baseTextures[angle]) return this._baseTextures[angle];
    return null;
  }

  setLarge(url: string) {
    this._large = new Texture(this._gl, url, this._index);
  }

  async partLoad(model: string, material: any, isTemplate: boolean) {
    if (cache[isTemplate ? this._positions[material.templateKey] : this._positions[material.id]])
      return cache[isTemplate ? this._positions[material.templateKey] : this._positions[material.id]];

    try {
      if (isTemplate) {
        const url = generateTemplateJSONUrl(model, this._key, material.templateKey);
        if (cache[url]) return cache[url];
        const res = await fetch(url, { headers: { Origin: window?.location?.origin || 'https://example.com' } });
        const data = await res.json();

        const info = { ...data, position: data.region };

        this._positions[material.templateKey] = info;
        cache[url] = info;

        return this._positions[material.templateKey];
      } else {
        const url = generateJSONUrl(model, this._key, material.id);
        if (cache[url]) return cache[url];
        const res = await fetch(url, { headers: { Origin: window?.location?.origin || 'https://example.com' } });
        const data = await res.json();

        const positions = data.filter((position: any) => position.name.includes('4-'));

        this._positions[material.id] = positions;
        cache[url] = positions;

        return this._positions[material.id];
      }
    } catch (error) {
      return [];
    }
  }
}
