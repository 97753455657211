import {
  DEFAULT_FLOOR_IMAGE_URL,
  DEFAULT_FLOORLIGHT_IMAGE_URL,
  generateShadowUrl,
  generateMaskUrl,
} from './urlGenerators';
import Part from '../../../core/ViewerManager/Part';
import Texture from '../../../core/ViewerManager/Texture';

const _handleAngle = async (
  opengl: any,
  state: any,
  parts: any,
  angleIndex: number,
  model: any,
  isFeatured: boolean,
  cb = () => {},
) => {
  const angle = ('00' + (angleIndex * 45 + state.startingAngle)).slice(-3);

  const _partPositions = await Promise.all(
    parts.map((part: any) => part.partLoad(model?.id, state.parts[part._key], state.parts[part._key].isTemplate)),
  );

  const partPositions: any = _partPositions.reduce((positions: any, position: any, index: number) => {
    const partKey = Object.keys(state.parts)[index];

    return { ...positions, [partKey]: position };
  }, {});

  const environment = [];
  const _floor = opengl?.getFloorAtAngle(angleIndex);
  // console.log('FLOOR SET');
  const _shadow = opengl?.getShadowAtAngle(angleIndex);
  const _maskUrl = generateMaskUrl(state.model, angle);
  const _mask = await opengl?.setMask(_maskUrl, angleIndex);
  // console.log('MASK SET');
  const _shadowUrl = generateShadowUrl(state.model, angle);
  const _floorUrl = DEFAULT_FLOOR_IMAGE_URL.replace('__MODEL__', state.size).replace('__ANGLE__', angle);

  if (_floor?._url !== _floorUrl) environment.push(await opengl?.addFloor(_floorUrl, angleIndex));
  // console.log('FLOOR SET');
  if (_shadow?._url !== _shadowUrl) environment.push(await opengl?.addShadow(_shadowUrl, angleIndex));
  // console.log('SHADOW SET');
  if (!opengl?._floorLightSet)
    environment.push(await opengl?.addFloorLight(DEFAULT_FLOORLIGHT_IMAGE_URL.replace('__MODEL__', state.size)));
  // console.log('FLOORLIGHT SET');

  const _parts = parts
    .map(async (part: any) => {
      const partKey: string = part?._key || '';
      const material: any = state.parts[partKey];
      const existingPart: Part | null = opengl?.getPart(partKey);
      const existingAngle: Texture | null | undefined = existingPart?.getAngle(angleIndex);

      if (material.isTemplate) {
        const url = part?.imageUrl.replace('__ANGLE__', angle);
        const _textureUrl = url?.replace('__IMAGE__', 'notBase');

        const { position, maxBase, maxNotBase, ...props } = partPositions?.[partKey];
        console.log('TEMPLATE', maxNotBase, props);
        const { rX4: x, rY4: y, rWidth: width, rHeight: height } = position || {};

        const baseColor = [
          material.baseColor?.r !== undefined ? material.baseColor.r : 1,
          material.baseColor?.g !== undefined ? material.baseColor.g : 1,
          material.baseColor?.b !== undefined ? material.baseColor.b : 1,
        ];
        const notBaseColor = [
          material.notBaseColor?.r !== undefined ? material.notBaseColor.r : 1,
          material.notBaseColor?.g !== undefined ? material.notBaseColor.g : 1,
          material.notBaseColor?.b !== undefined ? material.notBaseColor.b : 1,
        ];

        const templateDetails = {
          maxBase,
          maxNotBase,
          baseColor, //: baseColor.map((_) => _ / 255),
          notBaseColor, //: notBaseColor.map((_) => _ / 255),
        };

        if (_textureUrl === existingAngle?._url) {
          part.setTemplateDetails(templateDetails);
          return null;
        }

        part.setIsTemplate(true);

        console.time(`base-${material.id}`);
        await part?.addBaseTextureToAngle(
          url?.replace('__IMAGE__', 'Base') || '',
          angleIndex,
          { x: x + 0.25 / 4096, y, width: width - 1.5 / 4096, height },
          templateDetails,
        );
        console.timeEnd(`base-${material.id}`);
        return part?.addTextureToAngle(_textureUrl || '', angleIndex, {
          x: x + 0.25 / 4096,
          y,
          width: width - 1.5 / 4096,
          height,
        });
      } else {
        const url = part?.imageUrl.replace('__ANGLE__', angle);
        if (url === existingAngle?._url) return null;
        const position = partPositions?.[partKey]?.find((position: any) => position.name.includes(angle));
        const { rX: x, rY: y, rWidth: width, rHeight: height } = position || {};

        part.setIsTemplate(false);
        return part?.addTextureToAngle(url || '', angleIndex, {
          x,
          y,
          width,
          height,
        });
      }
    })
    .filter((_: any) => !!_);

  const promises = [...environment, ..._parts];
  await Promise.all(promises);

  await opengl?.createTextureFromPartsForAngle(parts, angleIndex);
  return opengl?.render(angleIndex, isFeatured);
};

export default _handleAngle;
