export default `
attribute vec4 aVertexPosition;
attribute vec4 aVertexColor;
attribute vec2 aTextureCoord;

uniform mat4 uModelViewMatrix;
uniform mat4 uProjectionMatrix;

varying lowp vec4 vColor;
varying highp vec2 vTextureCoord;

void main() {
  vColor = aVertexColor;
  vTextureCoord = aTextureCoord;
  gl_Position = aVertexPosition;
}
`;
