import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useFurnitureState } from 'Providers/state';

const Unit = React.memo(({ name, id, part, active, featured, status, group, attachments }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const timeout = useRef(null);
  const { changeMaterial } = useFurnitureState();
  const _handleSelect = () => {
    changeMaterial(part, id);
  };
  const _handleDetails = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDetailsVisible(true);
  };

  return (
    <>
      <Modal
        key={`modal-${id}`}
        isOpen={detailsVisible}
        onClose={() => setDetailsVisible(false)}
        {...{
          name,
          id,
          banner: attachments?.find((attachment) => attachment.role === 'featured-close')?.url || featured?.url,
        }}
      />
      <div
        onClick={_handleSelect}
        onDoubleClick={_handleDetails}
        className={`material-wrapper ${active ? 'active' : ''}`}
      >
        <div className='img-wrapper'>
          {active && <div className='active-dot'></div>}
          <img
            src={`${featured?.url || 'https://peacemakersnetwork.org/wp-content/uploads/2019/09/placeholder.jpg'}`}
            alt=''
          />
        </div>
        <span className='name'>{name?.replace(group, '').trim()}</span>
      </div>
    </>
  );
});

const Modal = ({ message, isOpen, onClose, banner, name }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => setIsLoaded(false), [isOpen]);
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div className='modal-backdrop'>
      <div className='backdrop' onClick={onClose} />

      <button onClick={onClose}>Close</button>
      <div className='modal'>
        <div className={`loader-wrapper ${isLoaded ? 'hidden' : ''}`}>
          <img src='/loader.png' alt='loading indicator' className='loader' />
        </div>
        <h1>{name}</h1>
        <img
          src={banner}
          onLoad={() => setTimeout(() => setIsLoaded(true), 520)}
          className={`unit ${isLoaded ? 'visible' : 'hidden'}`}
        />
      </div>
    </div>,
    document.querySelector('#modal'),
  );
};

export default Unit;
