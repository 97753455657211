import React, { createContext, useContext, useEffect, useState } from 'react';
import { state as GET_STATE } from 'queries/furniture';
import { useEnvironment } from 'Providers/environment';
const Context = createContext();

export const withState = (Comp) => (props) => (
  <Context.Consumer>{(context) => <Comp {...{ ...props, context }} />}</Context.Consumer>
);
export const useFurnitureState = () => useContext(Context);

const Index = ({ children }) => {
  const { furniture, endpoint, token, isFeatured } = useEnvironment();
  const [state, setState] = useState({ state: {} });
  const [isLoading, setIsLoading] = useState(false);

  let loadingTimeout;
  const finishLoading = () => {
    clearTimeout(loadingTimeout);
    setIsLoading(false);
  };
  const startLoading = () => {
    loadingTimeout = setTimeout(() => {
      setIsLoading(true);
    }, 120);
  };
  const _handleLoad = async (furnitureState) => {
    const body = {
      query: GET_STATE,
      variables: {
        furniture,
        state: furnitureState,
      },
    };

    try {
      const res = await fetch(`${endpoint}/graphql`, {
        method: 'POST',
        headers: {
          'Accept-Encoding': 'gzip, deflate, br',
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Connection: 'keep-alive',
          DNT: '1',
          Origin: window.location.origin,
          authorization: token,
          'X-Content': 'released',
        },
        body: JSON.stringify(body),
      });
      const { data, error } = await res.json();
      if (error || !data) return;

      setState(data.viewer);
    } catch (error) {
      throw new Error("Failed to load furniture's state: " + error.message);
    }
  };

  useEffect(() => {
    _handleLoad();
  }, []);

  const _handleMaterialupdate = (part, material) => {
    startLoading(true);
    const currentStateObj = JSON.parse(JSON.stringify(state));
    const currentState = state?.state;
    const specials = JSON.parse(JSON.stringify(state?.specials));

    // setting part
    currentState[part] = material;

    // Handling special materials
    const isSpecial = specials?.[part]?.includes(material);
    const specialIndex = currentState.specials?.indexOf(material) || -1;
    if (isSpecial && specialIndex === -1) currentState.specials.push(material);
    else if (!isSpecial && specialIndex > -1) currentState.specials.splice(specialIndex, 1);

    // We can set the state here, because probably the server's answer won't be different; but if yes, there is nothing wrong, the wrong renders will be canceled.
    setState({ ...currentStateObj, state: { ...currentState }, offline: true, isLoading });

    _handleLoad(currentState);
  };

  return (
    <Context.Provider value={{ ...state, changeMaterial: _handleMaterialupdate, finishLoading, isLoading, isFeatured }}>
      {children}
    </Context.Provider>
  );
};

export default Index;
