export default () => ({
  positions: generatePositions(),
  textureCoordinates: generateTextureCoordinates(),
  instances: generateInstances(),
});

const generatePositions = (): number[] => [
  -1.0, -1.0,
  1.0, -1.0,
  1.0, 1.0,
  -1.0, 1.0,
];

const generateTextureCoordinates = (): number[] => [
  0, 0,
  1, 0,
  1, 1,
  0, 1
];

const generateInstances = (): number[] => [
  0, 1, 2,
  0, 2, 3
];