import React from 'react'
import { unitComparer } from 'core';

const Title = React.memo(({ title, id, togglePart, units, options, partState, active }) => {
  const activeUnit = units?.find(unit => unitComparer(unit.id, partState));
  const activeOption = options?.find(option => partState === option);

  const _handleClick = () => togglePart?.(id);
  return (
    <div
      className={`part-title ${active ? 'active' : ''}`}
      onClick={_handleClick}
    >
      <span className="name">{ title }</span>
      <span className="active-unit">{ activeOption || activeUnit?.name }</span>
      <img src="/dropdown.svg" alt='dropdown' className="dropdown"/>
    </div>
  )
});

export default Title;
