import Texture from './Texture';
import draw from './draw';
import { createFrameBuffer, bindFramebuffer, unbindFrameBuffer } from './helpers/framebuffer';

interface IEnvironmentInput {
  floor: Texture | null;
  shadow: Texture | null;
  wall: WebGLTexture | null;
  floorLight: Texture | null;
  mask?: Texture | null;
}

export default (
  gl: WebGLRenderingContext,
  programInfo: any,
  buffers: any,
  { floor, wall, shadow, floorLight, mask }: IEnvironmentInput,
): WebGLTexture => {
  const { render, framebuffer } = createFrameBuffer(gl);
  // Switching to desired shader program
  gl.useProgram(programInfo?.program);

  // binding wall
  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uWallTexture`), 0);
  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, wall || null);

  // binding floor
  gl.uniform4f(
    programInfo.uniformLocations.uPartPosition,
    floor?._x || 0,
    floor?._y || 0,
    floor?._width || 1,
    floor?._height || 1,
  );
  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uFloorTexture`), 1);
  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, floor?.texture || null);

  // binding floor light
  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uFloorLightTexture`), 2);
  gl.activeTexture(gl.TEXTURE2);
  gl.bindTexture(gl.TEXTURE_2D, floorLight?.texture || null);

  // binding shadow
  gl.uniform4f(
    programInfo.uniformLocations.uShadowPosition,
    shadow?._x || 0,
    shadow?._y || 0,
    shadow?._width || 1,
    shadow?._height || 1,
  );
  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uShadowTexture`), 3);
  gl.activeTexture(gl.TEXTURE3);
  gl.bindTexture(gl.TEXTURE_2D, shadow?.texture || null);

  // binding mask
  gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uMaskTexture`), 4);
  gl.activeTexture(gl.TEXTURE4);
  gl.bindTexture(gl.TEXTURE_2D, mask?.texture || null);

  // gl.uniform1i(gl.getUniformLocation(programInfo?.program, `uFrameTexture`), 1);
  // gl.activeTexture(gl.TEXTURE1);
  // gl.bindTexture(gl.TEXTURE_2D, frame);

  bindFramebuffer(gl, framebuffer);
  draw(gl, programInfo, buffers, true);
  unbindFrameBuffer(gl);

  gl.deleteFramebuffer(framebuffer);

  return render;
};
