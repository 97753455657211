const CryptoJS = require('crypto-js');
const key = process.env.REACT_APP_KEY;

export const handleEncrypt = (msg: string) => CryptoJS.AES.encrypt(msg, key).toString();
export const handleDecrypt = (msg: string) => {
  const bytes = CryptoJS.AES.decrypt(msg, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// console.log(handleEncrypt(partfs));

// @ts-ignore
// window.handleEncrypt = handleEncrypt;
export default {
  handleEncrypt,
  handleDecrypt,
};
